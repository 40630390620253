import React, { useState, useEffect } from 'react';
import { DashboardLayout, PopupModal } from '../Components';
import "./Orders.css";
import { MdChevronRight, MdClose, MdOutlineSearch, MdCheck, MdOutlineEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axiosConfig from '../Service/axiosConfig'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useDebouncedValue from "../Utils/Debounce";

const CreateOrder = () => {
    const [show, setShow] = useState(false);
    const [page, setPage] = useState(1);
    const handleClose = () => {
        setShow(false)
        setSearch('')
        setPage(1)
    }
    const handleShow = () => setShow(true);
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const debouncedSearchTerm = useDebouncedValue(search, 500);
    let store_id = JSON.parse(localStorage.getItem('user_data')).store
    const [order, setOrder] = useState({
        products: [],
        client: '',
        ordered_date: '',
        delivered_date: '',
    });
    const [count, setCount] = useState(0)
    const [clients, setClients] = useState([])
    const [selectedClient, setSelectedClient] = useState(null);
    const [popupType, setpopupType] = useState("")
    const [Error, SetError] = useState("")
    const navigate = useNavigate()
    const { order_id } = useParams()
    const location = useLocation()
    const { client_data, order_data } = location.state || {};
    const [message, setMessage] = useState(null)
    const [LoadingButton, setLoadingButton] = useState();
    const GetProducts = async () => {
        setLoading(true)
        try {
            // const response = await axiosConfig.get(`catlog/products/?client_id=${selectedClient.value}&page_size=10&page=${page}&name=${debouncedSearchTerm}&is_suspended=false`)
            const response = await axiosConfig.get(`clients/tariffs/?client=${selectedClient.value}&product=&price=&page=${page}&page_size=10&search=${debouncedSearchTerm}`)
            setCount(response.data.count)
            if (page === 1) {
                let products = response.data.results.map((product) => ({
                    ...product.product,
                    price: product.price,
                    available: product.available
                }))
                setProducts(products);
            } else {
                setProducts((prev) => {
                    // Create a set of unique product IDs from the existing products (prev)
                    const existingProductIds = new Set(prev.map(prevprod => prevprod.id));
                    // Filter out products from the new results that already exist in prev
                    const uniqueNewProducts = response.data.results.map(prod => ({ ...prod.product, price: prod.price, available: prod.available })).filter(prod => !existingProductIds.has(prod.id));
                    const nonUniqueProducts = response.data.results.map(prod => ({ ...prod.product, price: prod.price, available: prod.available })).filter(prod => existingProductIds.has(prod.id));
                    console.log(nonUniqueProducts, page, "non unique")

                    // Combine the unique products with the existing products
                    return [...prev, ...uniqueNewProducts];
                });
            }
            setLoading(false)
        } catch (error) {
            console.log("Error getting products:", error)
            setLoading(false)
        }
    }

    const GetOrderDetails = async () => {
        try {
            const response = await axiosConfig(`/accounts/order-details/?order=${order_id}&page_size=1000`)
            console.log("Order details : ", response.data.results)
            const products = response.data.results.map((product) => ({
                id: product.product.id,
                min_qty: product.quantity,
                image: product.product.image ? product.product.image.image : '',
                price: product.price,
                name: product.product.name,
                available: product.available
            }))
            setOrder((prev) => ({
                ...prev,
                products: products,
                client: client_data.id,
                ordered_date: order_data.order_date ? order_data.order_date.split('T')[0] : null,
                delivered_date: order_data.delivered_time ? order_data.delivered_time.split('T')[0] : null,
            }))
            setSelectedClient({ label: client_data.client_name, value: client_data.id, delivery_agent: response.data.results[0].order.delivery_agent.full_name })
        } catch (error) {
            console.log("Error getting details:", error)
        }
    }

    const handleSelect = (item) => {
        const existingProductIndex = order.products.findIndex((product) => product.id === item.id);
        if (item.available) {
            if (existingProductIndex !== -1) {
                setOrder((prev) => ({
                    ...prev,
                    products: prev.products.filter((product) => product.id !== item.id),
                }));
            } else {
                console.log(order)
                const newProduct = {
                    id: item.id,
                    min_qty: 1,
                    image: item.image ? item.image.image : '',
                    price: item.price,
                    name: item.name,
                    available: item.available
                };
                setOrder((prev) => ({
                    ...prev,
                    products: [...prev.products, newProduct],
                }));
                const existing = {
                    ...order,
                    products: [...order.products, newProduct],
                }
                console.log("Clicked item : ", item.name, existing)
            }
        }
    };

    const HandleBlur = (e, item) => {
        const productIndex = order.products.findIndex((product) => product.id === item.id);
        if (productIndex !== -1) {
            let updatedProducts = [...order.products];
            const value = parseFloat(e.target.value)
            if (value === 0 || isNaN(value)) {
                updatedProducts.splice(productIndex, 1);
            }
            setOrder(prev => ({
                ...prev,
                products: updatedProducts,
            }))
        }
    }

    const HandleQtyChange = (e, item) => {
        const productIndex = order.products.findIndex((product) => product.id === item.id);
        if (productIndex !== -1) {
            let updatedProducts = [...order.products];
            console.log(typeof (e.target.value), "type check")
            const value = parseFloat(e.target.value)
            if (!isNaN(value) && value > -0.01) {
                updatedProducts[productIndex] = {
                    ...updatedProducts[productIndex],
                    min_qty: value,
                };
            } else {
                updatedProducts[productIndex] = {
                    ...updatedProducts[productIndex],
                    min_qty: '',
                };
            }
            setOrder(prev => ({
                ...prev,
                products: updatedProducts,
            }));
        }
    };


    const createOrder = async () => {
        if (order.products.length > 0) {
            const product = order.products
                .map((product) => ({
                    id: product.id,
                    min_qty: product.min_qty,
                }))
                .filter((product) => product.min_qty !== 0 && product.min_qty !== '');

            const order_to_post = {
                ...order,
                products: product,
                ordered_date: order.ordered_date,
                delivered_date: order.delivered_date
            };
            if (!order_to_post.ordered_date) {
                delete order_to_post.ordered_date
            }
            if (!order_to_post.delivered_date) {
                delete order_to_post.delivered_date
            }
            setLoadingButton(true)
            try {
                if (order_id) {
                    const response = await axiosConfig.put(`/accounts/orders/${order_id}/`, order_to_post, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    console.log("Order updated:", response);
                    setpopupType("success");
                    setLoadingButton(false)
                    setMessage("Order Updated Successfully")
                } else {
                    const response = await axiosConfig.post(`/accounts/orders/`, order_to_post, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    const status_update = await axiosConfig.post(`/accounts/orders/${response.data.id}/order_status_update/`, { orderstatus: 'Confirmed' })
                    console.log("Order created:", response.data.orderstatus, status_update);
                    setLoadingButton(false)
                    setpopupType("success");
                    setMessage("Order Created Successfully")
                }
            } catch (error) {
                console.log("Error creating/updating order:", error);
                setLoadingButton(false)
                setpopupType("error");
                if (error.message) {
                    console.log("caught network error")
                    SetError(error.message)
                }
                if (Object.keys(error.response.data)[0] === "The following products are not available in the store:") {
                    SetError("Store is unassigned for this client / Some products are not available in the store")
                }
                if (error.response.data.error) {
                    SetError(error.response.data.error)
                }
            }
        }
    };


    const handleCancel = () => {
        setShow(false);
        setSearch('')
        setPage(1)
        setOrder({
            products: [],
            client: '',
            ordered_date: null,
            delivered_date: null,
        })
    }

    const handleAdd = () => {
        selectedClient && setShow(true)
    }

    const handleDelete = (id) => {
        const productIndex = order.products.findIndex((product) => product.id === id);
        const updatedProducts = [...order.products];
        updatedProducts.splice(productIndex, 1);
        setOrder((prev) => ({
            ...prev,
            products: updatedProducts,
        }));
    }

    const handleScroll = (e) => {
        const { clientHeight, scrollTop, scrollHeight } = e.target
        if (clientHeight + scrollTop >= scrollHeight - 1 && Math.ceil(count / 10) > page) {
            setPage(page + 1)
        }
    }

    const handleSearch = async (value) => {
        setSearch(value)
        setPage(1)
    }

    const GetClients = async () => {
        if (clients.length === 0) {
            try {
                const response = await axiosConfig.get(`/clients/clients/?page_size=1000&&store=${store_id ? store_id : ""}&is_suspended=false`)
                const clientsArray = response.data.results.map((result) => ({
                    value: result.id,
                    label: result.client_name,
                    delivery_agent: result.delivery_agent ? result.delivery_agent.full_name : null
                }));
                setClients(clientsArray)
            } catch (error) {
                console.log("Error getting clients : ", error)
            }
        }
    }

    const handleDateChange = (e) => {
        const { name, value } = e.target
        if (name === "Ordered Date") {
            setOrder((prev) => ({
                ...prev,
                ordered_date: value
            }))
        } else if (name === "Delivered Date") {
            setOrder((prev) => ({
                ...prev,
                delivered_date: value
            }))
        }
    }

    const handleClientChange = (selectedOption, name) => {
        if (name === "Client") {
            setSelectedClient(selectedOption);
            setOrder((prev) => ({
                ...prev,
                client: selectedOption.value,
                products: []
            }))
        }
    };

    useEffect(() => {
        // window.onpopstate = (e) => {
        //     console.log("event", e)
        //     const result = window.confirm("Are you sure you want to leave this page?")
        //     if(result){
        //         window.onpopstate = null
        //     }else{
        //         e.preventDefault();
        //         window.history.go(1)
        //     }
        // }
        const handlePageUnload = (e) => {
            e.preventDefault();
            return (e.returnValue = '');
        };

        window.addEventListener('beforeunload', handlePageUnload, { capture: true });

        return () => {
            window.removeEventListener('beforeunload', handlePageUnload, { capture: true });
        };
    }, []);

    useEffect(() => {
        GetProducts();
        GetClients();
        if (order_id && !selectedClient) {
            GetOrderDetails();
        }
    }, [debouncedSearchTerm, selectedClient, page])
    return (
        <DashboardLayout>
            <div className='create-ord-top'>
                <div className='create-ord-left'>
                    <div>Orders</div>
                    <MdChevronRight />
                    {order_id ?
                        <div style={{ color: 'black' }}>Modify Order</div>
                        :
                        <div style={{ color: 'black' }}>Create Order</div>
                    }
                </div>
                <div className='create-ord-right'>
                    <button className='btn btn-white' onClick={() => navigate('/dashboard/orders')}>Cancel</button>
                    {order_id ?
                        <button className={order.products.length > 0 ? 'btn btn-primary' : 'btn btn-inactive'} onClick={createOrder}>
                            {LoadingButton ? <div>
                                <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." className="loader-image" />
                            </div> :
                                "Update Order"}
                        </button>
                        :
                        <button className={order.products.length > 0 ? 'btn btn-primary' : 'btn btn-inactive'} onClick={createOrder}>
                            {LoadingButton ? <div>
                                <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." className="loader-image" />
                            </div> :
                                "Create Order"}
                        </button>
                    }
                </div>
            </div>
            <div className='m_20 row'>
                {order_data &&
                    <div className='details-sub col-6'>
                        <label className='f_14_500'>
                            Order ID : <span className='f_14_400'>{order_id}</span>
                        </label>

                        <label className='f_14_500 d-flex align-items-center'>
                            <span style={{ flexShrink: 0 }}>Ordered Date : </span>
                            <input
                                type="date"
                                name="Ordered Date"
                                value={order.ordered_date}
                                onChange={(e) => handleDateChange(e)}
                                className='form-control ms-3'
                            />
                        </label>
                    </div>
                }
                {order_data && order_data.invoice &&
                    <div className='details-sub col-6'>
                        <label className='f_14_500'>
                            Invoice ID : <span className='f_14_400'>{order_data.invoice.invoice_id}</span>
                        </label>

                        <label className='f_14_500 d-flex align-items-center'>
                            <span style={{ flexShrink: 0 }}>Delivered Date :</span>
                            <input
                                type="date"
                                name="Delivered Date"
                                value={order.delivered_date}
                                onChange={(e) => handleDateChange(e)}
                                className='form-control ms-3'
                            />
                        </label>
                    </div>
                }
            </div>
            <div className='row m_20'>
                <div className='col-6'>
                    <p>Client</p>
                    <Select
                        placeholder="Client"
                        options={clients}
                        value={selectedClient}
                        onChange={(selectedOption) => handleClientChange(selectedOption, "Client")}
                        className='f_13_500'
                        isDisabled={order_id ? true : false}
                    />
                    <div className='create-ord-card'>
                        <div className='create-card-top'>
                            <div className='f_14_500'>Items {order.products.length > 0 && `(${order.products.length})`}</div>
                            <button className={selectedClient ? 'btn btn-primary' : 'btn btn-inactive'} onClick={() => handleAdd()}>+ Add Item</button>
                        </div>
                        <div>
                            {order.products.length > 0 ?
                                <div className='ord-items'>
                                    {order.products.map((product) => {
                                        return (
                                            <div className='product-main'>
                                                <div className='product-info'>
                                                    <div className='img-contaoner-modal'>
                                                        <img src={product.image} />
                                                    </div>
                                                    <div className='product-stock'>
                                                        <div className='product-name'>{product.name}</div>
                                                        <div className='f_14_400' style={{ color: '#8C8C8C' }}>Price: ₹{product.price}  |   {product.available ? 'In Stock' : 'Out of Stock'}</div>
                                                    </div>
                                                </div>
                                                <div className='product-info'>
                                                    <input
                                                        type='number'
                                                        value={product.min_qty}
                                                        className='product-qty'
                                                        onChange={(e) => HandleQtyChange(e, product)}
                                                        onWheel={(e) => e.target.blur()}
                                                        onBlur={(e) => HandleBlur(e, product)}
                                                    />
                                                    <div className='f_12_400' style={{ width: '60px' }}>₹{product.price}</div>
                                                    <RiDeleteBinLine size={15} color='#1B7D78' className='delete-icon' onClick={() => handleDelete(product.id)} />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <div style={{ textAlign: 'center', paddingTop: '60px', paddingBottom: '120px' }}>
                                    Add items to the order
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <p>Delivery Agent</p>
                    <p className='delivery-agent'>
                        {selectedClient ? selectedClient.delivery_agent ? selectedClient.delivery_agent : 'No Delivery Agent Assigned' : '--- Select client to see Delivery Agent ---'}
                    </p>
                    <div className='create-ord-card'>
                        <div className='create-card-top'>
                            <div className='f_14_500'>Order Summary</div>
                        </div>
                        <div>
                            {order.products.length > 0 ?
                                <div>
                                    <div className='subtotal'>
                                        <div className='f_14_400 rowBetweenCenter'>
                                            <div>Subtotal</div>
                                            <div>
                                                ₹{order.products.reduce((total, product) => total + product.price * product.min_qty, 0)}
                                            </div>
                                        </div>
                                        <div className='f_14_400 rowBetweenCenter'>
                                            <div>Tax</div>
                                            <div>₹0.00</div>
                                        </div>
                                    </div>
                                    <div className='f_14_500 total rowBetweenCenter'>
                                        <div>Total</div>
                                        <div>
                                            ₹{order.products.reduce((total, product) => total + product.price * product.min_qty, 0)}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={{ paddingLeft: '20px', paddingTop: '20px', paddingBottom: '20px' }}>
                                    No items have been added yet
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                centered
                size="lg"
            >
                <Modal.Body>
                    <div className='add-header'>
                        <div>Add a Product</div>
                        <MdClose onClick={() => handleClose()} />
                    </div>
                    <div className='search-input'>
                        <MdOutlineSearch size={17} />
                        <input type='text' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
                    </div>
                    <div className='product-list' onScroll={(e) => handleScroll(e)}>
                        {products.length > 0 &&
                            products.map((product) => {
                                return (
                                    <div className='add-header' onClick={() => handleSelect(product)}>
                                        <div className='product-info'>
                                            <div className='rowBetweenCenter gap-20'>
                                                <div className='item-checkbox'>
                                                    {order.products.some((p) => p.id === product.id) && <MdCheck color='#1B7D78' />}
                                                </div>
                                                <div className='img-contaoner-modal'>
                                                    <img src={product.image ? product.image.image : ''} />
                                                </div>
                                                <div>
                                                    <div>{product.name}</div>
                                                </div>
                                            </div>
                                            {!product.available ?
                                                <div className='out-of-stock'>out of stock</div>
                                                :
                                                order.products.some((p) => p.id === product.id) ?
                                                    <input
                                                        type='number'
                                                        defaultValue={0}
                                                        value={order.products.find((p) => p.id === product.id).min_qty}
                                                        className='product-qty'
                                                        onChange={(e) => HandleQtyChange(e, product)}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onWheel={(e) => e.target.blur()}
                                                        onBlur={(e) => HandleBlur(e, product)}
                                                    />
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <div className='f_12_400'>₹{product.price}</div>
                                    </div>
                                )
                            })
                        }
                        {loading &&
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src={require('../Components/FormParser/images/loader.gif')} style={{ width: '30px', height: '30px' }} />
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-primary' onClick={() => handleCancel()}>cancel</button>
                    <button className={order.products.length > 0 ? 'btn btn-primary' : 'btn btn-inactive'} onClick={handleClose}>
                        Add to Order
                    </button>
                </Modal.Footer>
            </Modal>
            {popupType != "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} Navigation="/dashboard/orders" error={Error} Message={message} />
            }
        </DashboardLayout>
    )
}

export { CreateOrder }
