import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import { MdOutlineEdit, MdOutlineHomeWork, MdStar, MdOutlinePhone, MdOutlineMailOutline } from "react-icons/md"
import axiosConfig from "../Service/axiosConfig"
import { useParams, useNavigate } from "react-router-dom";
import { BiArrowBack, BiCommentEdit } from "react-icons/bi";
import { RiMapPin2Line } from "react-icons/ri"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IoNavigate } from "react-icons/io5";
import { FaLessThanEqual } from "react-icons/fa";
import { ClientTariff } from "./Tariff";
const ClientEdit = ({ Code }) => {
    let { id } = useParams();
    console.log(id, "iddddd")
    const navigate = useNavigate();
    const [LoadingButton, setLoadingButton] = useState();
    const [key, setKey] = useState('Info');
    const [readOnly, setReadOnly] = useState(true);
    const [readOnly2, setReadOnly2] = useState(true);
    const [readOnly3, setReadOnly3] = useState(true);
    const [popupType, setpopupType] = useState("");
    const [loading, setLoading] = useState(false)
    const MastersModal = Masters.ClientEdit
    const MastersModal2 = Masters.ClientStore
    const MasterModal3 = Masters.ClientAgreement
    const [error, setError] = useState(null);
    const [Data, SetData] = useState({
        client_name: '',
        logo: '',
        email: '',
        mobile: '',
        address: '',
        latitude: '',
        longitude: '',
        client_status: 'Active',
        agreement: '',
        business_pan: '',
        partner_aadhar: '',
        company_firm: '',
        gst: '',
        trade_license: '',
        address_proof: '',
        cancelled_check: ''
    });
    const [storeData, setStoreData] = useState({
        store: '',
        delivery_agent: ''
    })
    const [ClientData, setClientData] = useState({
        client_type: '',
        contract_from_date: '',
        contract_to_date: '',
        agreement: ''
    })
    const GetData = () => {
        setLoading(true)
        axiosConfig.get(`/clients/clients/${id}/`).then(res => {
            console.log(res.data)
            const deliveryAgentId = res.data.delivery_agent ? res.data.delivery_agent.id : null;
            const storeId = res.data.store ? res.data.store.id : null;
            const Client_data = {
                ...res.data,
                delivery_agent: deliveryAgentId,
                store: storeId
            };
            console.log(Client_data, "inside")
            SetData({ ...res.data, business_vertical: res.data.business_vertical.id })
            setStoreData(Client_data);
            const dateObject = res.data.contract_to_date ? new Date(res.data.contract_to_date) : null;
            const formattedDate = dateObject ? dateObject.toISOString().split('T')[0] : null;
            const fromObject = res.data.contract_from_date ? new Date(res.data.contract_from_date) : null;
            const FromDate = fromObject ? fromObject.toISOString().split('T')[0] : null;
            setClientData({ ...res.data, contract_to_date: formattedDate, contract_from_date: FromDate });
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }
    const submitFormData = (formData, setFieldErrors) => {
        console.log(formData, "formdata")
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        setLoadingButton(true)
        axiosConfig
            .patch(`/clients/clients/${id}/`, formData)
            .then((res) => {
                console.log(res);
                setReadOnly(true);
                setReadOnly2(true);
                setReadOnly3(true);
                setLoadingButton(false)
                setpopupType('success');
                GetData();
            })
            .catch((error) => {
                console.log(error, "error");
                if (error.response.data.error) {
                    setFieldErrors(prev => ({ ...prev, ["slug"]: "A Miscellaneous Page with this slug already exists." }));
                }
                if (error.response.data.email) {
                    setFieldErrors(prev => ({ ...prev, ["email"]: "Email already exists." }));
                }
                setReadOnly(false);
                setReadOnly2(false);
                setReadOnly3(false);
                setpopupType('error');
                setLoadingButton(false)
            });
    };
    const formSubmit = (formData, setFieldErrors) => {
        console.log(formData);
        const formDataToSend = new FormData()
        formDataToSend.append("client_name", formData.client_name);
        formDataToSend.append("logo", formData.logo.id);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("mobile", formData.mobile);
        formDataToSend.append("address", formData.address);
        formDataToSend.append("latitude", formData.latitude);
        formDataToSend.append("longitude", formData.longitude);
        formDataToSend.append("business_vertical", formData.business_vertical);
        formDataToSend.append("business_pan", formData.business_pan);
        formData.partner_aadhar && formDataToSend.append("partner_aadhar", formData.partner_aadhar.id)
        formDataToSend.append("company_firm", formData.company_firm);
        formDataToSend.append("gst", formData.gst);
        formData.trade_license && formDataToSend.append("trade_license", formData.trade_license.id);
        formData.address_proof && formDataToSend.append("address_proof", formData.address_proof.id);
        formData.cancelled_check && formDataToSend.append("cancelled_check", formData.cancelled_check.id);
        submitFormData(formDataToSend, setFieldErrors);
    };
    const formSubmit2 = (formData, setFieldErrors) => {
        console.log(formData);
        submitFormData(formData, setFieldErrors);
    };
    const formSubmit3 = (formData, setFieldErrors) => {
        console.log(formData);
        if (formData.client_type === "Contracted") {
            if (!formData.contract_from_date || !formData.contract_to_date) {
                setFieldErrors(prev => ({ ...prev, ["contract_from_date"]: "Required" }));
                setFieldErrors(prev => ({ ...prev, ["contract_to_date"]: "Required" }));
                return;
            }
            if (formData.contract_to_date < formData.contract_from_date) {
                setFieldErrors(prev => ({ ...prev, ["contract_to_date"]: "To date is not less than from date." }));
                return;
            }
        }
        setError(null);
        const formDataToSend = new FormData()
        formDataToSend.append("client_type", formData.client_type);
        formDataToSend.append("contract_from_date", formData.contract_from_date ? formData.contract_from_date : '');
        formDataToSend.append("contract_to_date", formData.contract_to_date ? formData.contract_to_date : "");
        formDataToSend.append("agreement", formData.agreement ? formData.agreement.id : '');
        submitFormData(formDataToSend, setFieldErrors);
    };
    const handleMapClick = (latitude, longitude) => {
        console.log(latitude, longitude)
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}&travelmode=driving`)
    }
    console.log(Data)
    useEffect(() => {
        GetData()
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="client-options">
                    <BiArrowBack onClick={() => navigate("/dashboard/masters/clients/")} className="option-icon" />
                </div>
                <div className="client-info">
                    <div className="client-logoo">
                        <img src={Data.logo && Data.logo.image !== undefined ? Data.logo.image : ''} alt="Client logo" className="logo-image" />
                    </div>
                    <div className="customer-info">
                        <div className="contact-detile">
                            <MdOutlineHomeWork className="contact-icons" />
                            <div className="data-text">{Data.client_name}</div>
                            <a href={`tel:+${Data.mobile}`}><MdOutlinePhone className="support-icons" /></a>
                        </div>
                        <div className="contact-map">
                            <RiMapPin2Line className="contact-icons" />
                            <div className="data-text">{Data.address}</div>
                            <IoNavigate className="support-icons" onClick={() => handleMapClick(Data.latitude, Data.longitude)} />
                        </div>
                        <div className="contact-rating">
                            <div className="contact-email contact-emails"><MdOutlineMailOutline className="contact-icons" />{Data.email}</div>
                            <div className="contact-phone"><MdOutlinePhone className="contact-icons" />{Data.mobile}</div>
                        </div>
                        <div className={`client-type ${Data.client_type === 'Contracted' ? 'contract-background' : 'variable-background'}`}>{Data.client_type}</div>
                    </div>
                </div>
                <div className="client-form">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3">
                        <Tab eventKey="Info" title="Info">
                            <FormParser
                                modelObject={MastersModal}
                                formSubmit={formSubmit}
                                formData={Data}
                                error={Error}
                                buttonTitle='Save'
                                LoadingButton={LoadingButton}
                                setLoadingButton={setLoadingButton}
                                readOnly={readOnly}
                                setReadOnly={setReadOnly}
                            />
                            {readOnly &&
                                <button className={`btn btn-primary d-flex align-items-center`} onClick={() => setReadOnly(false)}>
                                    <div>Edit</div>
                                    <MdOutlineEdit className="ms-5" />
                                </button>
                            }
                        </Tab>
                        <Tab eventKey="Store & Delivery Agent" title="Store & Delivery Agent">
                            <FormParser
                                modelObject={MastersModal2}
                                formSubmit={formSubmit2}
                                formData={storeData}
                                error={Error}
                                buttonTitle='Save'
                                readOnly={readOnly2}
                                setReadOnly={setReadOnly2}
                            />
                            {readOnly2 &&
                                <button className={`btn btn-primary d-flex align-items-center`} onClick={() => setReadOnly2(false)}>
                                    <div>Edit</div>
                                    <MdOutlineEdit className="ms-5" />
                                </button>
                            }
                        </Tab>
                        <Tab eventKey="Agreements" title="Agreements">
                            <FormParser
                                modelObject={MasterModal3}
                                formSubmit={formSubmit3}
                                formData={ClientData}
                                error={error}
                                buttonTitle='Save'
                                readOnly={readOnly3}
                                setReadOnly={setReadOnly3}
                            />
                            {readOnly3 &&
                                <button className={`btn btn-primary d-flex align-items-center`} style={{ marginLeft: '30px' }} onClick={() => setReadOnly3(false)}>
                                    <div>Edit</div>
                                    <MdOutlineEdit className="ms-5" />
                                </button>
                            }
                        </Tab>
                        <Tab eventKey="Tariff" title="Tariff">
                            <ClientTariff />
                        </Tab>
                    </Tabs>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} Message="Client Updated Sucessfully" error={error} />
                }
            </DashboardLayout >
        </>
    )

}
export { ClientEdit }